import React from 'react';
import styled from 'styled-components';
import { BodyLarge, H3 } from './typography/Typography';
import { Button, Container } from './ui';
import { minBreakpointQuery } from '../styles';

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 0;
  padding: 80px 0;

  ${minBreakpointQuery.medium`
  justify-content: flex-start;
`}
`;

const StyledHeadline = styled(H3)`
  text-align: center;
`;

const StyledInfoText = styled(BodyLarge)`
  text-align: center;
`;

const StyledButton = styled(Button)``;

const ThankYou = () => {
  return (
    <Container>
      <StyledInfo>
        <StyledHeadline>You completed your checkout!</StyledHeadline>
        <StyledInfoText>
          Thank you for your purchase! Now you can continue visiting our
          website!
        </StyledInfoText>
        <StyledButton href="/">Return to home page</StyledButton>
      </StyledInfo>
    </Container>
  );
};

export default ThankYou;
